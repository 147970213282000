import {createSlice} from '@reduxjs/toolkit';

interface State {
  orderData: any[];
  showRequestMobileForm: boolean;
}
export const initialState: State = {
  orderData: [],
  showRequestMobileForm: false,
};

export const addRequestSlice = createSlice({
  name: 'addRequest',
  initialState,
  reducers: {
    addItem: (state, action) => {
      const newItem = action.payload;
      const existingItemIndex = state.orderData.findIndex(
        item => item.id === newItem.id,
      );
      if (existingItemIndex !== -1) {
        // If the item already exists, update its count
        state.orderData[existingItemIndex].quntum +=
          newItem.quantityIncremental;
      } else {
        // If the item doesn't exist, add it to the list
        state.orderData.unshift({
          ...newItem,
          quntum: newItem.minimumQuantity > newItem.quantityIncremental ? newItem.minimumQuantity:newItem.quantityIncremental,
        });
      }
    },

    removeItem: (state, action) => {
      const newItem = action.payload;

      const existingItemIndex = state.orderData.findIndex(
        item => item.id === newItem.id,
      );

      if (existingItemIndex !== -1) {
        // If the item exists, decrement its count
        state.orderData[existingItemIndex].quntum -=
          newItem.quantityIncremental;

        if (state.orderData[existingItemIndex].quntum === 0 || state.orderData[existingItemIndex].quntum < newItem.minimumQuantity) {
          // If the item's count becomes 0, remove it from the list
          state.orderData.splice(existingItemIndex, 1);
        }
      }
    },
    clearOrder: state => {
      state.orderData = [];
    },
    setShowRequestMobileForm: (state, action) => {
      state.showRequestMobileForm = action.payload;
    },
  },
});

export const {addItem, removeItem, clearOrder, setShowRequestMobileForm} =
  addRequestSlice.actions;

export default addRequestSlice.reducer;
