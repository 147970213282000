import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../hooks';
import { setLanguage } from '../store/features/localSlice';
import { isAuthenticated } from 'src/configs/OAuthConfig';
import { updateUserPreferredLanguage } from 'src/services/ProfileServices';

const useChangeLanguage = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.local);

  useEffect(() => {
    i18n.changeLanguage(data.language);
    const html = document.getElementsByTagName('html');
    html[0].setAttribute('dir', data.dir);
  }, [data.dir, data.language, i18n]);

  const changeLanguage = (lang: string) => {
    dispatch(setLanguage(lang));
    i18n.changeLanguage(lang);
    if (isAuthenticated) {
      updateUserPreferredLanguage({languageCode:lang.split('-')[0]}).then(_=>{
        window.location.reload();
      });
    }
  };
  return { changeLanguage };
};

export default useChangeLanguage;
